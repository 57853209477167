<template>
  <TblStdRecord />
</template>

<script>
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx
import TblStdRecord from "./components/TblStdRecord";

export default {
  name: "Dashboard",
  components: { XLSX, TblStdRecord },
  data() {
    return {
      selectedCiteria: null,
      responseContent: null,
      darkModal: false,
      exportingData: null,
      teacherInfo: null,
      selectedTeacher: null,
      userData: this.$cookies.get("user"),

      levelData: null,
    };
  },
  mounted() {
    //FETCH LEVEL ABV NAME
    this.axios
      .get(this.$hostUrl + "php_action/levelAPI.php?txtAction=getAlleLevel")
      .then((response) => {
        this.levelData = response.data;
        let levelA_name = response.data.mainData;
      });

    let schoolCode = this.userData.user_address;
    //FETCH UPDATED PROFILE
    this.axios
      .get(
        this.$hostUrl +
          "php_action/recordAPI.php?txtAction=getNullLat&schoolCode=" +
          schoolCode
      )
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
      });
  },
  methods: {
    openModal(attId) {
      this.darkModal = true;
      this.selectedAttId = attId;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&txtAttId=" +
            this.selectedAttId
        )
        .then((response) => {
          this.selectedData = response.data.mainData;
          this.selectedCiteria = { code: this.selectedData.tc_citeria_no };
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => {});
    },
    updateData() {
      //SEND DATA
      const form = document.getElementById("formUpdateAbs");
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("sltCiteria", this.selectedCiteria.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            this.responseContent = response.data.error;
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },
    setSelected(value) {
      this.selectedCiteria = value;
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/attendanceAPI.php?txtAction=delete&attId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    updateTableForTeacher() {
      let teacherId = this.selectedTeacher.code;

      //FETCH Semester Stats BY TEACHER
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=getStatByTeacher&selectedSemester=" +
            this.$route.params.semesterId +
            "&teacherId=" +
            teacherId
        )
        .then((response) => {
          this.rows = response.data.mainData;
          if (this.rows == undefined) {
            this.rows = [{}];
          }
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
