<template>
  <CCol>
    <CCard v-if="rows">
      <CCardHeader class="bg-dark text-yellow">ระเบียนประวัติ</CCardHeader>
      <CCardBody>
        <div v-if="rows && levelData">
          <vue-good-table :columns="columns" :rows="rows" :line-numbers="true" :search-options="{ enabled: true }"
            :sort-options="{
      enabled: true,
      initialSortBy: { field: 'level_abv_name', type: 'asc' },
    }" :pagination-options="{
      enabled: true,
      mode: 'page',
      perPage: 50,
    }">
            >
            <div slot="table-actions">
              <button class="btn btn-yellow" style="margin-right: 10px" @click="modalCreate = true">
                +Add
              </button>
            </div>
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'link'">
                <router-link :to="{
      name: 'ระเบียนประวัติรายบุคคล',
      params: {
        stdId: props.row.stud_id,
        schoolCode: userData.user_address,
      },
    }" class="badge badge-primary">view</router-link>
              </div>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
        <div v-else>Loading...</div>

        <CRow style="margin-top: 20px">
          <CCol>
            <div style="text-align: right">
              <button @click="onExport" class="btn btn-secondary">
                Export
              </button>
              <!-- เพิ่มปุ่ม Export -->
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <div v-else>
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <!----------------MODAL CREATE-------------------------------------------------------------------------->
    <form id="formCreateStd" v-on:submit.prevent="createStd" method="POST">
      <CModal :show.sync="modalCreate" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
        color="dark">
        <CRow><!--body-->
          <CCol lg="6">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              รหัสประจำตัวนักเรียน:
            </label>
            <input type="text" name="stdId" class="form-control" v-model="stdId" required />
          </CCol>
          <CCol lg="6">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              เลขประจำตัวประชาชน:
            </label>
            <input type="text" name="pin" class="form-control" required />
          </CCol>
          <CCol lg="6">
            <SltPrefix />
          </CCol>
          <CCol lg="6"> </CCol>

          <CCol lg="6">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              ชื่อ:
            </label>
            <input type="text" name="stdName" class="form-control" required />
          </CCol>
          <CCol lg="6">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              นามสกุล:
            </label>
            <input type="text" name="stdSurname" class="form-control" required />
          </CCol>
          <CCol lg="6">
            <SltLevel />
          </CCol>
          <CCol lg="6">
            <SltClass />
          </CCol>
          <CCol lg="6">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              เลขที่:
            </label>
            <input type="text" name="stdOrdinal" class="form-control" required />
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">เพิ่มระเบียนประวัตินักเรียน</h3>
          </h6>
          <CButtonClose @click="modalCreate = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalCreate = false" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-yellow">save</button>
        </template>
      </CModal>
    </form>
  </CCol>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx
import SltPrefix from "./SltPrefix";
import SltLevel from "./SltLevel";
import SltClass from "./SltClass";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    SltPrefix,
    SltLevel,
    SltClass,
  },
  data() {
    return {
      showModal: false,
      selectedAttId: null,
      selectedData: null,
      citeriaInfo: null,
      selectedCiteria: null,
      responseContent: null,
      darkModal: false,
      exportingData: null,
      teacherInfo: null,
      selectedTeacher: null,
      userData: this.$cookies.get("user"),
      columns: [
        {
          label: "รหัสนักเรียน",
          field: "stud_id",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "ชั้น",
          field: "level_abv_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: [
              "ป.1",
              "ป.2",
              "ป.3",
              "ป.4",
              "ป.5",
              "ป.6",
              "ม.1",
              "ม.2",
              "ม.3",
              "ม.4",
              "ม.5",
              "ม.6",
              "ปวช.1",
              "ปวช.2",
              "ปวช.3",
            ], // dropdown (with selected values) instead of text input
          },
          width: '10%'
        },
        {
          label: "ห้อง",
          field: "std_class",
          type: "number",
          filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
            ], // dropdown (with
          },
          width: '10%'
        },
        {
          label: "โรคประจำตัว",
          field: "std_CD",
        },
        {
          label: "links",
          field: "link",
        },
      ],
      rows: null,
      levelData: null,
      modalCreate: false,
      stdId: null,
    };
  },
  mounted() {
    //FETCH LEVEL ABV NAME
    this.axios
      .get(this.$hostUrl + "php_action/levelAPI.php?txtAction=getAlleLevel")
      .then((response) => {
        this.levelData = response.data;
        let levelA_name = response.data.mainData;
        //console.log(this.levelAbvName);
      });

    let schoolCode = this.userData.user_address;
    //FETCH UPDATED PROFILE
    this.axios
      .get(
        this.$hostUrl +
        "php_action/recordAPI.php?txtAction=getNullLat&schoolCode=" +
        schoolCode
      )
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
      });
  },
  methods: {
    openModal(attId) {
      this.darkModal = true;
      this.selectedAttId = attId;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=get&txtAttId=" +
          this.selectedAttId
        )
        .then((response) => {
          this.selectedData = response.data.mainData;
          this.selectedCiteria = { code: this.selectedData.tc_citeria_no };
        })
        .finally(() => { });
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => { });
    },
    createStd(event) {
      //SEND DATA
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("prefixCode", this.$store.state.selectedPrefix.code);
      formData.append("stdPass", this.stdId);
      formData.append("stdLevel", this.$store.state.selectedLevel.code);
      formData.append("stdClass", this.$store.state.selectedClass);
      formData.append("stdSchool", this.userData.user_address);

      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.createState == "created") {
            //this.responseContent = response.data.error;
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
          }
        });
    },
    setSelected(value) {
      this.selectedCiteria = value;
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=delete&attId=" +
            value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => { });
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    updateTableForTeacher() {
      let teacherId = this.selectedTeacher.code;

      //FETCH Semester Stats BY TEACHER
      this.axios
        .get(
          this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=getStatByTeacher&selectedSemester=" +
          this.$route.params.semesterId +
          "&teacherId=" +
          teacherId
        )
        .then((response) => {
          this.rows = response.data.mainData;
          if (this.rows == undefined) {
            this.rows = [{}];
          }
        })
        .finally(() => { });
    },
  },
};
</script>